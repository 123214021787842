import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/layout'
import HeaderSlim from "../components/HeaderSlim";
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Beleserv"/>

    <HeaderSlim />
    <h1>It seems we haven't created this page yet.</h1>
    <p>Please return to <Link to="/">the homepage</Link> and start over.</p>
  </Layout>
);

export default NotFoundPage
