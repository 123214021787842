import React from 'react'

import logo from '../assets/images/beleserv.png';

const HeaderSlim = (props) => (
  <header id="header" className="slim">
    <span className="logo"><img src={logo} alt="" /></span>
  </header>
);

export default HeaderSlim;
